import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useState } from "react";
import Logo from "../asset/images/logo.png";
import { Link } from "react-router-dom";

export const Authheader = () => {
  return (
    <>
    
    <header className="authheader">
     
   

      <nav className="navbar navbar-theme navbar-static-top navbar-expand-lg header-sticky ">
        <div className="container-fluid d-flex justify-content-start">
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse"
          >
            <i className="fas fa-align-left" />
          </button>
          <Link to="/" className="navbar-brand m">
            <img className="img-fluid" src={Logo} alt="logo" />
          </Link>

        

        </div>
      </nav>
    </header>
  </>
  )
}

export default Authheader;
