import { faAlignLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useEffect, useState } from "react";
import Logo from "../asset/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "../utlilites/axios";
import swal from "sweetalert";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
// import '../pages/Custom.css';

export const Header = () => {
  const Signin = "Sign In";

  const [data, setData] = useState(null);

  const profile = () => {
    const token = localStorage.getItem("accesstoken");
    var config = {
      method: "get",
      url: "http://admin.usys.club/api/rental/profile/view",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setData(response.data);
        console.log("===> Login", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    profile();
  }, []);
  const Navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    swal({
      title: "Good job!",
      text: "Rental Logout SuccessFully!",
      icon: "success",
      button: "Ok",
    });
    Navigate("/login");
  };
  const token = localStorage.getItem("accesstoken");

 
  return (
    <>
      <div className="detailheader">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="bg-theme"
          variant="white"
        >
          <Container fluid>
            <Navbar.Brand href="#home">
              <img src={Logo} alt="" width={"100%"} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav">
              <FontAwesomeIcon icon={faAlignLeft} className="text-white" />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto">
               
              
              </Nav>
              <Nav className="ms-auto">
                <Nav.Link className="">
                  <Link
                    className="btn btn-success btn-block  xsmall"
                    to="/landlordlogin"
                  >
                    Post For Free
                  </Link>
                </Nav.Link>

                {data === null ? (
                  <Nav.Link className="">
                    <Link
                      className="text-white btn btn-success text-white btn-block"
                      to="/userlogin"
                    >
                      Sign In
                    </Link>
                  </Nav.Link>
                ) : (
                  <NavDropdown
                    className="text-white pt-2"
                    title={data?.rental?.email}
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item class>
                      <a
                        className="btn btn-success btn-block xsmall"
                        onClick={logout}
                      >
                        Logout
                      </a>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                  </NavDropdown>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;

{
  /* <NavDropdown
  className=""
  id="collasible-nav-dropdown"
>
  {data === null ? (
    <NavDropdown.Item className="active pl-50">
      <Link to="/userlogin" className="">
        Sign in
      </Link>
    </NavDropdown.Item>
  ) : (
    <NavDropdown.Item className="active">
      <p className="">
        {data?.admin_profile?.email}
      </p>
    </NavDropdown.Item>
  )}
  <hr className="position" />{" "}
  <NavDropdown.Item className="active pl-50">
    <a className="" href="#" onClick={logout}>
      logout
    </a>
  </NavDropdown.Item>
</NavDropdown> */
}
