import { height } from '@mui/system';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
 
function Maps({google}) {
    
  
  
    return (
      <Map google={google} zoom={14}
        containerStyle={{width:'49%', height:'81%'}}
        >
 
        <Marker    name={'Current location'}  />
 
      </Map>
    );
  }

export default GoogleApiWrapper({
    apiKey: ("AIzaSyAt9IOK_D-YxRKKyZgzwyJxxWO503VzHEQ")
  })(Maps)