import  {useEffect} from "react";
import Authfooter from "../../component/Authfooter";
import Authheader from "../../component/Authheader";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { setSession } from "../../utlilites/jwt";
import swal from "sweetalert";
import axios from "../../utlilites/axios";

export const Usersignup = () => {
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      'Must match "password" field value'
    ),
    phone_number: Yup.string()
      .required("Phone Number Is Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(12, "Please enter 12 digits"),
  });
  const Navigate = useNavigate();

  const signupfunc = async (V) => {
    try {
      const response = await axios.post("/register", V);
      console.log(response, "workig");
      const { message, status, token } = response.data;
      if (status === true) {
        setSession(token);
        swal({
          title: "Good job!",
          text: message,
          icon: "success",
          button: "Ok",
        });
        Navigate("/");
      } else {
      }
    } catch (errors) {
      console.log(errors.errors[0]);
      swal({
        title: "Something Went Wrong",
        text: errors.errors[0],
        icon: "error",
        dangerMode: true,
      });
    }
  };
//   const token = localStorage.getItem("accesstoken");
//   useEffect(()=>{
//     console.log(token)
// if(token){
//  Navigate("/");
// }else{
//   Navigate("/userlogin")
// }
//   },[])
  return (
    <>
      <Authheader />
      <Formik
        initialValues={{
          email: "",
          phone_number: "",
          password: "",
          password_confirmation: "",
          role: "rental",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          console.log(values, "values");
          // same shape as initial values
          signupfunc(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="container-fluid py-1-7 bg-login">
              <div className="row">
                <div className="offset-lg-2 col-lg-8">
                  <div className="card shadow border-radious bg-transparent">
                    <div className="card-header">
                      <h4 className="color fw-bolder text-center">
                        Welcome To Rental Sign Up
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <label className="form-label text-white">Email</label>
                          <Field
                            name="email"
                            type="email"
                            className=" form-control mb-3"
                            placeholder="Enter Your Email"
                          />
                          {errors.email && touched.email ? (
                            <p className="text-danger">{errors.email}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-6">
                          <label className="form-label text-white">
                            Phone Number
                          </label>
                          <Field
                            name="phone_number"
                            type="number"
                            className="col-lg-6 form-control mb-3"
                            placeholder="Enter Your phone number"
                          />
                          {errors.phone_number && touched.phone_number ? (
                            <p className="text-danger">{errors.phone_number}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-6">
                          <label className="form-label text-white">
                            Password
                          </label>
                          <Field
                            name="password"
                            type="password"
                            className="col-lg-6 form-control mb-3"
                            placeholder="Enter Your Password"
                          />
                          {errors.password && (
                            <p className="text-danger">{errors.password}</p>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="form-label text-white">
                            Confirm Password
                          </label>
                          <Field
                            name="password_confirmation"
                            type="password"
                            className="col-lg-6 form-control mb-3"
                            placeholder="Enter Your Password"
                          />
                          {errors.password_confirmation && (
                            <p className="text-danger">
                              {errors.password_confirmation}
                            </p>
                          )}
                        </div>
                        {/* <div className="col-lg-6">
                          <label className="form-label text-white">
                            Role
                          </label>
                          <Field
                            name="role"
                            type="text"
                            className="col-lg-6 form-control mb-3"
                            value="Rental"
                            disabled
                          />
                        </div> */}
                        <button
                          type="submit"
                          className="btn btn-success offset-lg-3 col-lg-6 mt-5"
                          // onClick={()=>alert("ok")}
                        >
                          SignUp
                        </button>
                        <div className="col-lg-12 text-center">
                          <p className="color">
                            If You Have Account ?{" "}
                            <Link to="/login" className="text-white">
                              Login
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <Authfooter />
    </>
  );
};

export default Usersignup;
