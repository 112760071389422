import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./component/Footer";
// import Footer from "./component/Footer";
// import Header from "./component/Header";
import Home from "./pages/Home";
import Userlogin from "./pages/Rental-Auth/Userlogin";
import Usersignup from "./pages/Rental-Auth/Usersignup";
import Landlordlogin from "./pages/Landlord-Auth/Landlordlogin";
import Landlordsignup from "./pages/Landlord-Auth/Landlordsignup";
import Landlorddashboard from "./pages/Landlord/Landlorddashboard";
import Searchapp from "./component/Search";
import Property from "./pages/Landlord/Property";
import Edit_property from "./pages/Landlord/Edit_property";
import Addproperty from "./pages/Landlord/Addproperty";
import Propertydetails from "./pages/rental/Propertydetails";
import AllProperty from "./pages/rental/AllProperty";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Userlogin />} />
          <Route exact path="/usersignup" element={<Usersignup />} />
          <Route exact path="/landlordlogin" element={<Landlordlogin />} />
          <Route exact path="/landlordsignup" element={<Landlordsignup />} />
          <Route
            exact
            path="/landlorddashboard"
            element={<Landlorddashboard />}
          />
          <Route exact path="/landlordproperty" element={<Property />} />
          <Route exact path="/editproperty/:id" element={<Edit_property />} />
          <Route exact path="/landlordaddproperty" element={<Addproperty />} />
          <Route exact path="/AllProperty" element={<AllProperty />} />
          <Route exact path="/propertydetail:id" element={<Propertydetails />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
