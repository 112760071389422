import { Delete, Search, Style, Update } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import Authfooter from "../../component/Authfooter";
import Authheader from "../../component/Authheader";
import Searchapp from "../../component/Search";
import SideBar from "../../component/SideBar";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import Logo from "../../asset/images/logo1.png";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
// import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "../../utlilites/axios";
// import loader from "../../asset/images/GTA-loader.gif";
import Dropdown from "react-bootstrap/Dropdown";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import swal from "sweetalert";
const { Header, Sider, Content } = Layout;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const Property = () => {
  const [gtaloader, setGtaLoader] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const navigate = new useNavigate();
  const [columns, setColumns] = useState([
    { title: "Name", field: "name" },
    {
      title: "Surname",
      field: "surname",
      initialEditValue: "initial edit value",
    },
    { title: "Birth Year", field: "birthYear", type: "numeric" },
    {
      title: "Birth Place",
      field: "birthCity",
      lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    },
  ]);

  const [data, setData] = useState([
    { name: "Mehmet", surname: "Baran", birthYear: 1987, birthCity: 63 },
    { name: "Zerya Betül", surname: "Baran", birthYear: 2017, birthCity: 34 },
  ]);
  const userID = localStorage.getItem("user");
  //delete API
  const { Id } = useParams();

  const deldata = (rowData) => {
    try {
      const token = localStorage.getItem("accesstoken");
      var config = {
        method: "get",
        url: `http://admin.usys.club/api/landlord/delete/property/${rowData.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config).then(function (response) {
        setData(response.data);

        console.log(response.data, "wworking");
        // .catch(function (error) {
        //   console.log(error);
        // });
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this property!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            swal("Poof! Your imaginary file has been deleted!", {
              icon: "success",
            });
          } else {
            
          }
        });
       
      });
    } catch (error) {}
  };

  //delete API

  const [api, setApi] = useState();
  const details = () => {
    const token = localStorage.getItem("accesstoken");
    var config = {
      method: "get",
      url: `http://admin.usys.club/api/landlord/veiw/property/${userID}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setApi(response.data.property);
        console.log(response.data.property, "profile");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log("api", api);
  useEffect(() => {
    details();
  }, []);

  // Sidebar apis
  const [datas, setDatas] = useState(null);
  const profile = () => {
    const token = localStorage.getItem("accesstoken");
    var config = {
      method: "get",
      url: "http://admin.usys.club/api/landlord/profile/view",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setDatas(response.data);
        console.log(response.data, "wworking");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    profile();
  }, []);
  // const Navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    swal({
      title: "Good job!",
      text: "Rental Logout SuccessFully!",
      icon: "success",
      button: "Ok",
    });
    navigate("/landlordlogin");
  };

  // SideBar Apis end
  return (
    <>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo ">
            <img
              src={datas === null ? datas?.landlord?.image : Logo}
              alt=""
              height="100%"
              width="100%"
              className="img-thumbnail "
            />
          </div>
          <div>
            <div className="text-center mt-5 mb-3 fs-6 ">
              <h6 className="text-white">{datas?.landlord?.first_name}</h6>
            </div>
          </div>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["2"]}
            items={[
              {
                key: "1",
                icon: <UserOutlined />,
                label: <Link to="/landlorddashboard">DashBoard</Link>,
              },
              {
                key: "2",
                icon: <VideoCameraOutlined />,
                label: <Link to="/landlordproperty">Property</Link>,
              },
              {
                key: "3",
                icon: <UploadOutlined />,
                label: <Link to="/landlordaddproperty">Add Property</Link>,
              },
              // {
              //   key: '4',
              //   icon: <UploadOutlined />,
              //   label: 'Log Out',
              //   onClick: { logout }
              // },
            ]}
          />
        </Sider>

        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
            }}
          >
            <div className="row">
              <div className="col-md-6">
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: () => setCollapsed(!collapsed),
                  }
                )}
              </div>

              <div className="col-md-2"></div>
              <div className="col-md-4">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {datas?.landlord?.email}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <a
                        className="btn btn-success btn-block xsmall"
                        onClick={logout}
                      >
                        Logout
                      </a>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="text-right fs-6 mt-4 ms-2 ">
                <h6 className="text-dark">{datas?.landlord?.email}</h6>
              </div> */}
              </div>
            </div>
          </Header>

          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              height: "auto",
            }}
          >
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10 ms-auto me-auto my-3 p-0">
                    <div className="card shadow">
                      <MaterialTable
                        style={{ margin: "0px", padding: "0px" }}
                        icons={tableIcons}
                        title="Property Details"
                        columns={[
                          {
                            title: "Id",
                            field: "id",
                            cellStyle: { width: "1%" },
                          },
                          {
                            title: "Property Type",
                            field: "property_type",
                            cellStyle: { width: "20%" },
                          },
                          {
                            title: "Property SubType",
                            field: "sub_property_type",
                            cellStyle: { width: "20%" },
                          },
                          {
                            title: "Address",
                            field: "address",
                            cellStyle: { width: "20%" },
                          },
                          {
                            title: "Lease Term",
                            field: "lease_term",

                            cellStyle: { width: "20%" },
                          },
                        ]}
                        data={api}
                        actions={[
                          {
                            // headerStyle:{width: "10%"},

                            icon: () => <Edit style={{ color: "#26ae61" }} />,
                            tooltip: "Edit Property",
                            onClick: (value, rowData) => {
                              navigate("/editproperty/" + rowData.id);
                              console.log(rowData.id, "object");
                            },
                          },
                          {
                            icon: () => <Delete style={{ color: "red" }} />,
                            tooltip: "Delete Property",
                            onClick: (value, rowData) => {
                              deldata(rowData);
                            },
                          },
                        ]}
                        options={{
                          rowStyle: {
                            backgroundColor: "#EEE",
                          },
                          actionsColumnIndex: -1,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default Property;
