import {
  faBath,
  faBed,
  faBolt,
  faBuilding,
  faCalendar,
  faClock,
  faLocation,
  faMap,
  faMapLocation,
  faSearch,
  faShareAltSquare,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import image1 from "../asset/images/property/grid/01.jpg";
import image2 from "../asset/images/property/grid/02.jpg";
import image3 from "../asset/images/property/grid/03.jpg";
import loader from "../asset/images/GTA-loader.gif";
import Footer from "../component/Footer";
import Header from "../component/Header";
import Searchapp from "../component/Search";
import axios from "../utlilites/axios";

import "./Custom.css";
import { Link, useNavigate } from "react-router-dom";

export const Home = () => {
  // const [data, setData] = useState();
  // try {
  //   const response = await axiosInstance.get("/veiw/property", H);
  //   console.log(response, "workig");
  //   console.log("====> data", response.data);
  //   const { message, status } = response.data;
  //   console.log(message, "message");
  //   if (status === true) {
  //     swal({
  //       title: "Good job!",
  //       text: message,
  //       icon: "success",
  //       button: "Ok",
  //     });
  //   } else {
  //   }
  // } catch (errors) {
  //   console.log(errors.errors[0]);
  //   swal({
  //     title: "Something Went Wrong",
  //     text: errors.errors[0],
  //     icon: "error",
  //     dangerMode: true,
  //   });
  // }
  const navigate = new useNavigate();
  const [loader, setLoader] = useState(true);
  const [getproperty, setGetproperty] = useState();
  const [path, setPath] = useState();

  const property = () => {
    // const token = localStorage.getItem("accesstoken");
    var config = {
      method: "get",
      url: "http://admin.usys.club/api/veiw/property",
      headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(true);
        setGetproperty(response.data.property);
        setPath(response.data.imagepath);
        console.log(response, "checking");
        if (response.status) {
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log("api", getproperty);

  useEffect(() => {
    property();
    setLoader(true);
  }, []);

  return (
    <>
      <Header />

      {loader ? <div className="loader"></div> : null}
      {/* :<> */}
      <section className="banner bg-holder bg-overlay-black-30 banner-1">
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative my-5rem">
              <h1 className="text-white text-center mb-2 font fw-bolder">
                Renting Made Easy
              </h1>
              <p className="lead text-center text-white mb-4 fw-normal font">
                Thousands of apartments, houses, and condos for rent across
                Canada
              </p>
              <div className="col-12">
                <div className="container h-100">
                  <div className="d-flex justify-content-center h-100">
                    {/* <div className="searchbar">
                      <input
                        className="search_input"
                        type="text"
                        name=""
                        placeholder="Search..."
                      />
                      <a href="#" className="search_icon">
                        <FontAwesomeIcon icon={faSearch} />
                      </a>
                    </div> */}
                    <Searchapp />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-pb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center my-5">
                <h2>Popular Cities</h2>
                <p>Find your next apartment for rent anywhere in Canada</p>
              </div>
            </div>
          </div>
          {console.log(getproperty, "line 127")}
          <div className="row">
            {getproperty?.map((i) => {
              return (
                <div className="col-sm-6 col-md-3">
                  <div className="property-item">
                      <div onClick={() => navigate("/propertydetail" + i.id)}>
                    <div className="property-image bg-overlay-gradient-04">
                      {i.property_image.slice(0, 1).map((x) => (
                          <img
                            className=""
                            src={`${path}${x.image}`}
                            alt="NO image Found "
                            width={"100%"}
                            height={174.6}
                          />
                        
                      ))}
                    </div>
                    <div className="property-details">
                      <div className="property-details-inner">
                        <h5 className="property-title">
                          <a href="property-detail-style-01.html">
                            {i.property_type}
                          </a>
                        </h5>
                        <span className="property-address">
                          <FontAwesomeIcon icon={faBuilding} />
                          {"\u00a0\u00a0"}
                          {i.sub_property_type
                            ? i.sub_property_type
                            : "No Sub Property Found"}
                        </span>
                        <br />

                        <span className="property-address">
                          <FontAwesomeIcon icon={faLocation} />
                          {"\u00a0\u00a0"}
                          {i.address}
                        </span>
                        <br />
                        {i.floor.map((x) => {
                          return (
                            <span className="property-agent-date">
                              <FontAwesomeIcon icon={faCalendar} />
                              {"\u00a0\u00a0"}
                              {x.availability}
                            </span>
                          );
                        })}
                        <div className="property-price">
                          $
                          {i.floor.map((x) => {
                            return <>{x.rent}</>;
                          })}
                          <span> / month</span>
                        </div>
                        <ul className="property-info list-unstyled d-flex">
                          <li className="flex-fill property-bed">
                            <FontAwesomeIcon icon={faBed} />
                            {i.floor.map((x) => {
                              return (
                                <>
                                  Bed<span>{x.bedroom}</span>
                                </>
                              );
                            })}
                          </li>
                          <li className="flex-fill property-bath">
                            <FontAwesomeIcon icon={faBath} />
                            {i.floor.map((x) => {
                              return (
                                <>
                                  Bath<span>{x.bathroom}</span>
                                </>
                              );
                            })}
                          </li>
                          <li className="flex-fill property-m-sqft">
                            <FontAwesomeIcon icon={faSquare} /> sqft
                            {i.floor.map((x) => {
                              return (
                                <>
                                  <span>{x.unit_size}m</span>
                                </>
                              );
                            })}
                          </li>
                        </ul>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="mt-0 mb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-5"></div>
            <div className="col-lg-4 ">
              <Link to="/AllProperty" className="btn btn-outline-success btn-lg">
                See More......
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="space-pb bg-dark">
        <div className="container  mt-6">
          <br />
          <div className="row mt-5">
            <div className="col-sm-6 col-md-3">
              <ul className="list-group">
                <li className="list-group-item">
                  <a className="nav-link">Brampton Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Burnaby Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Fredericton Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Gatineau Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Kelowna Apartments</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-3">
              <ul className="list-group">
                <li className="list-group-item">
                  <a className="nav-link">Brampton Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Burnaby Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Freder Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Gatineau Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Kelowna Apartments</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-3">
              <ul className="list-group">
                <li className="list-group-item">
                  <a className="nav-link">Brampton Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Burnaby Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Fredericton Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Gatineau Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Kelowna Apartments</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-3">
              <ul className="list-group">
                <li className="list-group-item">
                  <a className="nav-link">Brampton Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Burnaby Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Fredericton Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Gatineau Apartments</a>
                </li>
                <li className="list-group-item">
                  <a className="nav-link"> Kelowna Apartments</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      {/* </>
      } */}

      {/* <section className="clearfix">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h2>Discover our best deals</h2>
                <p>
                  Check the listings of the best dealer on Real Villa and
                  contact the agency or its agent by phone or contact form.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="owl-carousel owl-nav-top-right"
                data-animateOut="fadeOut"
                data-nav-arrow="true"
                data-items="1"
                data-md-items="1"
                data-sm-items="1"
                data-xs-items="1"
                data-xx-items="1"
                data-space="0"
              >
                <div className="item">
                  <div className="property-offer">
                    <div className="property-offer-item">
                      <div
                        className="property-offer-image bg-holder"
                        style={{ background: "images/property/big-img-01.jpg" }}
                      >
                        <div className="row">
                          <div className="col-lg-6 col-md-10 col-sm-12">
                            <div className="property-details">
                              <div className="property-details-inner">
                                <h5 className="property-title">
                                  <a href="property-detail-style-01.html">
                                    Ample apartment at last floor{" "}
                                  </a>
                                </h5>
                                <span className="property-address">
                                  <i className="fas fa-map-marker-alt fa-xs"></i>
                                  Virginia drive temple hills
                                </span>
                                <span className="property-agent-date">
                                  <i className="far fa-clock fa-md"></i>10 days
                                  ago
                                </span>
                                <p className="mb-0 d-block mt-3">
                                  Use a past defeat as a motivator. Remind
                                  yourself you have nowhere to go except up as
                                  you.
                                </p>
                                <div className="property-price">
                                  $150.00<span> / month</span>{" "}
                                </div>
                                <ul className="property-info list-unstyled d-flex">
                                  <li className="flex-fill property-bed">
                                    <i className="fas fa-bed"></i>Bed
                                    <span>1</span>
                                  </li>
                                  <li className="flex-fill property-bath">
                                    <i className="fas fa-bath"></i>Bath
                                    <span>2</span>
                                  </li>
                                  <li className="flex-fill property-m-sqft">
                                    <i className="far fa-square"></i>sqft
                                    <span>145m</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="property-btn">
                                <a
                                  className="property-link"
                                  href="property-detail-style-01.html"
                                >
                                  See Details
                                </a>
                                <ul className="property-listing-actions list-unstyled mb-0">
                                  <li className="property-compare">
                                    <a
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="Compare"
                                      href="#"
                                    >
                                      <i className="fas fa-exchange-alt"></i>
                                    </a>
                                  </li>
                                  <li className="property-favourites">
                                    <a
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="Favourite"
                                      href="#"
                                    >
                                      <i className="far fa-heart"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="property-offer">
                    <div className="property-offer-item">
                      <div
                        className="property-offer-image bg-holder"
                        style={{ background: "images/property/big-img-02.jpg" }}
                      >
                        <div className="row">
                          <div className="col-lg-6 col-md-10 col-sm-12">
                            <div className="property-details">
                              <div className="property-details-inner">
                                <h5 className="property-title">
                                  <a href="property-detail-style-01.html">
                                    Luxury villa with pool
                                  </a>
                                </h5>
                                <span className="property-address">
                                  <i className="fas fa-map-marker-alt fa-xs"></i>
                                  West Indian St. Missoula
                                </span>
                                <span className="property-agent-date">
                                  <i className="far fa-clock fa-md"></i>2 years
                                  ago
                                </span>
                                <p className="mb-0 d-block mt-3">
                                  For those of you who are serious about having
                                  more, doing more, giving more and being more.
                                </p>
                                <div className="property-price">
                                  $698.00<span> / month</span>{" "}
                                </div>
                                <ul className="property-info list-unstyled d-flex">
                                  <li className="flex-fill property-bed">
                                    <i className="fas fa-bed"></i>Bed
                                    <span>5</span>
                                  </li>
                                  <li className="flex-fill property-bath">
                                    <i className="fas fa-bath"></i>Bath
                                    <span>4</span>
                                  </li>
                                  <li className="flex-fill property-m-sqft">
                                    <i className="far fa-square"></i>sqft
                                    <span>1,658m</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="property-btn">
                                <a
                                  className="property-link"
                                  href="property-detail-style-01.html"
                                >
                                  See Details
                                </a>
                                <ul className="property-listing-actions list-unstyled mb-0">
                                  <li className="property-compare">
                                    <a
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="Compare"
                                      href="#"
                                    >
                                      <i className="fas fa-exchange-alt"></i>
                                    </a>
                                  </li>
                                  <li className="property-favourites">
                                    <a
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="Favourite"
                                      href="#"
                                    >
                                      <i className="far fa-heart"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="space-ptb bg-holder-bottom building-space"
        style={{ backgroundImage: "images/building-bg.png" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-9">
              <div className="section-title mb-0">
                <h2>Plenty of reasons to choose us</h2>
                <p>
                  Our agency has many specialized areas, but our CUSTOMERS are
                  our real specialty.
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-lg-end">
              <a className="btn btn-primary" href="about-us.html">
                More about us{" "}
              </a>
            </div>
          </div>
          <div className="row g-0 mt-4">
            <div className="col-lg-3 col-sm-6">
              <div className="feature-info h-100">
                <div className="feature-info-icon">
                  <i className="flaticon-like"></i>
                </div>
                <div className="feature-info-content">
                  <h6 className="mb-3 feature-info-title">
                    Excellent reputation
                  </h6>
                  <p className="mb-0">
                    Our comprehensive database of listings and market info give
                    the most accurate view of the market and your home value.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-info h-100">
                <div className="feature-info-icon">
                  <i className="flaticon-agent"></i>
                </div>
                <div className="feature-info-content">
                  <h6 className="mb-3 feature-info-title">Best local agents</h6>
                  <p className="mb-0">
                    You are just minutes from joining with the best agents who
                    are fired up about helping you Buy or sell.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-info h-100">
                <div className="feature-info-icon">
                  <i className="flaticon-like-1"></i>
                </div>
                <div className="feature-info-content">
                  <h6 className="mb-3 feature-info-title">Peace of mind</h6>
                  <p className="mb-0">
                    Rest guaranteed that your agent and their expert team are
                    handling every detail of your transaction from start to end.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="feature-info h-100">
                <div className="feature-info-icon">
                  <i className="flaticon-house-1"></i>
                </div>
                <div className="feature-info-content">
                  <h6 className="mb-3 feature-info-title">Tons of options</h6>
                  <p className="mb-0">
                    Discover a place you’ll love to live in. Choose from our
                    vast inventory and choose your desired house.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-7 text-center">
              <p className="mb-4">
                Ten years and thousands of home buyers have turned to Real Villa
                to find their dream home. We offer a comprehensive list of
                for-sale properties, as well as the knowledge and tools to make
                informed real estate decisions. Today, more than ever, Real
                Villa is the home of home Search.
              </p>
              <div className="popup-video">
                <a
                  className="popup-icon popup-youtube"
                  href="https://www.youtube.com/watch?v=LgvseYYhqU0"
                >
                  {" "}
                  <i className="flaticon-play-button"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="testimonial-main bg-holder"
        style={{ backgroundImage: "images/bg/02.jpg" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div
                className="owl-carousel owl-dots-bottom-left"
                data-nav-dots="true"
                data-items="1"
                data-md-items="1"
                data-sm-items="1"
                data-xs-items="1"
                data-xx-items="1"
                data-space="0"
              >
                <div className="item">
                  <div className="testimonial">
                    <div className="testimonial-content">
                      <p>
                        <i className="quotes">"</i>Thank you Martin for selling
                        our apartment. We are delighted with the result. I can
                        highly recommend Martin to anyone seeking a truly
                        professional Realtor.
                      </p>
                    </div>
                    <div className="testimonial-name">
                      <h6 className="text-primary mb-1">Lisa & Graeme</h6>
                      <span>Hamilton Rd. Willoughby</span>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial">
                    <div className="testimonial-content">
                      <p>
                        <i className="quotes">"</i>Oliver always had a smile and
                        was so quick to answer and get the job done. I'll
                        definitely suggest you to anyone we know who is selling
                        or wanting to purchase a home. He is the best!
                      </p>
                    </div>
                    <div className="testimonial-name">
                      <h6 className="text-primary mb-1">Jessica Alex</h6>
                      <span>West Division Street</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h2>Meet our agents</h2>
                <p>
                  Real Villa Agents are customer advocates. We are accountable
                  for helping clients buy and sell the right home, at the right
                  price.
                </p>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-lg-3 col-sm-6 mb-4 mb-sm-0">
              <div className="agent text-center">
                <div className="agent-detail">
                  <div className="agent-avatar avatar avatar-xllll">
                    <img
                      className="img-fluid rounded-circle"
                      src="images/agent/01.jpg"
                      alt=""
                    />
                  </div>
                  <div className="agent-info">
                    <h6 className="mb-0">
                      {" "}
                      <a href="agent-detail.html">Alice Williams </a>
                    </h6>
                    <span className="text-primary font-sm">Founder & CEO </span>
                    <p className="mt-3 mb-0">
                      The first thing to remember about success is that it is a
                      process – nothing more, nothing less.
                    </p>
                  </div>
                </div>
                <div className="agent-button">
                  <a className="btn btn-light d-grid" href="agent-detail.html">
                    View Profile
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-4 mb-sm-0">
              <div className="agent text-center">
                <div className="agent-detail">
                  <div className="agent-avatar avatar avatar-xllll">
                    <img
                      className="img-fluid rounded-circle"
                      src="images/agent/02.jpg"
                      alt=""
                    />
                  </div>
                  <div className="agent-info">
                    <h6 className="mb-0">
                      {" "}
                      <a href="agent-detail.html">Felica queen </a>
                    </h6>
                    <span className="text-primary font-sm">Construction</span>
                    <p className="mt-3 mb-0">
                      There are basically six key areas to higher achievement.
                      Some people will tell you there are four.
                    </p>
                  </div>
                </div>
                <div className="agent-button">
                  <a className="btn btn-light d-grid" href="agent-detail.html">
                    View Profile
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-4 mb-sm-0">
              <div className="agent text-center">
                <div className="agent-detail">
                  <div className="agent-avatar avatar avatar-xllll">
                    <img
                      className="img-fluid rounded-circle"
                      src="images/agent/03.jpg"
                      alt=""
                    />
                  </div>
                  <div className="agent-info">
                    <h6 className="mb-0">
                      {" "}
                      <a href="agent-detail.html">Paul flavius </a>
                    </h6>
                    <span className="text-primary font-sm">Investment</span>
                    <p className="mt-3 mb-0">
                      While others may tell you there are eight. One thing for
                      certain though, is that irrespective of the.
                    </p>
                  </div>
                </div>
                <div className="agent-button">
                  <a className="btn btn-light d-grid" href="agent-detail.html">
                    View Profile
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="agent text-center">
                <div className="agent-detail">
                  <div className="agent-avatar avatar avatar-xllll">
                    <img
                      className="img-fluid rounded-circle"
                      src="images/agent/04.jpg"
                      alt=""
                    />
                  </div>
                  <div className="agent-info">
                    <h6 className="mb-0">
                      {" "}
                      <a href="agent-detail.html">Sara lisbon </a>
                    </h6>
                    <span className="text-primary font-sm">
                      Land development
                    </span>
                    <p className="mt-3 mb-0">
                      If success is a process with a number of defined steps,
                      then it is just like any other process.
                    </p>
                  </div>
                </div>
                <div className="agent-button">
                  <a className="btn btn-light d-grid" href="agent-detail.html">
                    View Profile
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h2>Most popular places</h2>
                <p>Discover homes in Real villa Most Popular Cities</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-6 mb-4 mb-lg-0">
                  <a href="property-grid.html">
                    <div
                      className="location-item bg-overlay-gradient bg-holder"
                      style={{ backgroundImage: "images/location/01.jpg" }}
                    >
                      <div className="location-item-info">
                        <h5 className="location-item-title">Mumbai</h5>
                        <span className="location-item-list">
                          10 Properties
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 mb-4 mb-md-0">
                  <a href="property-grid.html">
                    <div
                      className="location-item bg-overlay-gradient bg-holder"
                      style={{ backgroundImage: "images/location/02.jpg" }}
                    >
                      <div className="location-item-info">
                        <h5 className="location-item-title">Los angeles</h5>
                        <span className="location-item-list">
                          14 Properties
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-12 mt-0 mt-lg-4">
                  <a href="property-grid.html">
                    <div
                      className="location-item bg-overlay-gradient bg-holder"
                      style={{ backgroundImage: "images/location/04.jpg" }}
                    >
                      <div className="location-item-info">
                        <h5 className="location-item-title">Miami</h5>
                        <span className="location-item-list">
                          22 Properties
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <a href="property-grid.html">
                <div
                  className="location-item location-item-big bg-overlay-gradient bg-holder"
                  data-jarallax='{"speed": 0.6}'
                  data-jarallax-video="https://www.youtube.com/embed/kacyaEXqVhs?showinfo=0&amp;enablejsapi=1&amp;origin=https://themes.potenzaglobalsolutions.com/html/real-villa/"
                >
                  <div className="location-item-info">
                    <h5 className="location-item-title">San francisco </h5>
                    <span className="location-item-list">29 Properties</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pt bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-title">
                <h2>
                  Download our android and iOS app to get exciting features
                  prime content
                </h2>
                <p>
                  Our Real Estate app simplifies your home search. Spontaneously
                  see nearby homes for sale, apartments for rent.
                </p>
              </div>
              <div className="row">
                <div className="col-md-4 mb-5">
                  <div className="d-flex">
                    <div className="me-3">
                      <i className="flaticon-rent font-xlll"></i>
                    </div>
                    <h6 className="text-primary">Real time property listing</h6>
                  </div>
                </div>
                <div className="col-md-4 mb-5">
                  <div className="d-flex">
                    <div className="me-3">
                      <i className="flaticon-contract font-xlll"></i>
                    </div>
                    <h6 className="text-primary">Budget filter for budget</h6>
                  </div>
                </div>
                <div className="col-md-4 mb-5">
                  <div className="d-flex">
                    <div className="me-3">
                      <i className="flaticon-notification font-xlll"></i>
                    </div>
                    <h6 className="text-primary">
                      Notification price reduction
                    </h6>
                  </div>
                </div>
              </div>
              <div className="d-block d-sm-flex">
                <a
                  className="btn btn-dark btn-sm btn-app me-0 me-sm-2 mb-2 mb-sm-0"
                  href="#"
                >
                  <i className="fab fa-apple"></i>
                  <div className="btn-text text-start">
                    <small className="small-text">Download on the </small>
                    <span className="mb-0 text-white d-block">App store</span>
                  </div>
                </a>
                <a
                  className="btn btn-dark btn-sm btn-app mb-2 mb-sm-0"
                  href="#"
                >
                  <i className="fab fa-google-play"></i>
                  <div className="btn-text text-start">
                    <small className="small-text">Get in on </small>
                    <span className="mb-0 text-white d-block">google play</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-5 mt-5 mt-lg-0 text-center">
              <img className="img-fluid" src="images/mobile-app.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h2>News, tips & articles</h2>
                <p>
                  Grow your appraisal and real estate career with tips, trends,
                  insights and learn more about our expert's advice.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="blog-post">
                <div className="blog-post-image">
                  <img className="img-fluid" src="images/blog/01.jpg" alt="" />
                </div>
                <div className="blog-post-content">
                  <div className="blog-post-details">
                    <div className="blog-post-category">
                      <a className="mb-3" href="#">
                        <strong>Home improvement</strong>
                      </a>
                    </div>
                    <div className="blog-post-title">
                      <h5>
                        <a href="blog-detail.html">
                          7 pieces of advice to newbies
                        </a>
                      </h5>
                    </div>
                    <div className="blog-post-description">
                      <p className="mb-0">
                        You are going on a cruise, but when the ship sets sail,
                        you discover.
                      </p>
                    </div>
                  </div>
                  <div className="blog-post-footer">
                    <div className="blog-post-time">
                      <a href="#">
                        {" "}
                        <i className="far fa-clock"></i>02 Jan 2019
                      </a>
                    </div>
                    <div className="blog-post-author">
                      <span>
                        {" "}
                        By{" "}
                        <a href="#">
                          {" "}
                          <img
                            className="img-fluid"
                            src="images/avatar/01.jpg"
                            alt=""
                          />
                          Sara lisbon{" "}
                        </a>{" "}
                      </span>
                    </div>
                    <div className="blog-post-comment">
                      <a href="#">
                        {" "}
                        <i className="far fa-comment"></i>(12){" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4 mt-lg-0">
              <div className="blog-post">
                <div className="blog-post-image">
                  <img className="img-fluid" src="images/blog/02.jpg" alt="" />
                </div>
                <div className="blog-post-content">
                  <div className="blog-post-details">
                    <div className="blog-post-category">
                      <a className="mb-3" href="#">
                        <strong>Tips and advice</strong>
                      </a>
                    </div>
                    <div className="blog-post-title">
                      <h5>
                        <a href="blog-detail.html">
                          Where to Invest in Real Estate
                        </a>
                      </h5>
                    </div>
                    <div className="blog-post-description">
                      <p className="mb-0">
                        You will sail along until you collide with an immovable
                        object.
                      </p>
                    </div>
                  </div>
                  <div className="blog-post-footer">
                    <div className="blog-post-time">
                      <a href="#">
                        {" "}
                        <i className="far fa-clock"></i>14 Feb 2019
                      </a>
                    </div>
                    <div className="blog-post-author">
                      <span>
                        {" "}
                        By{" "}
                        <a href="#">
                          {" "}
                          <img
                            className="img-fluid"
                            src="images/avatar/02.jpg"
                            alt=""
                          />
                          Anne Smith
                        </a>{" "}
                      </span>
                    </div>
                    <div className="blog-post-comment">
                      <a href="#">
                        {" "}
                        <i className="far fa-comment"></i>(32){" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4 mt-lg-0">
              <div className="blog-post">
                <div className="blog-post-image">
                  <img className="img-fluid" src="images/blog/03.jpg" alt="" />
                </div>
                <div className="blog-post-content">
                  <div className="blog-post-details">
                    <div className="blog-post-category">
                      <a className="mb-3" href="#">
                        <strong>Market trends</strong>
                      </a>
                    </div>
                    <div className="blog-post-title">
                      <h5>
                        <a href="blog-detail.html">
                          Cutting your losses in Real Estate
                        </a>
                      </h5>
                    </div>
                    <div className="blog-post-description">
                      <p className="mb-0">
                        Trying to go through life without clarity is similar to
                        sailing.
                      </p>
                    </div>
                  </div>
                  <div className="blog-post-footer">
                    <div className="blog-post-time">
                      <a href="#">
                        {" "}
                        <i className="far fa-clock"></i>30 March 2019
                      </a>
                    </div>
                    <div className="blog-post-author">
                      <span>
                        {" "}
                        By{" "}
                        <a href="#">
                          {" "}
                          <img
                            className="img-fluid"
                            src="images/avatar/03.jpg"
                            alt=""
                          />
                          Alice Williams
                        </a>{" "}
                      </span>
                    </div>
                    <div className="blog-post-comment">
                      <a href="#">
                        {" "}
                        <i className="far fa-comment"></i>(45){" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-primary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-9">
              <h2 className="text-white mb-0">
                Looking to sell or rent your property?
              </h2>
            </div>
            <div className="col-lg-3 text-lg-end mt-3 mt-lg-0">
              <a className="btn btn-white" href="#">
                Get a free quote
              </a>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  );
};

export default Home;
