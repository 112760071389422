import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import GoogleMapReact from 'google-map-react';
import Maps from "../../component/Maps";
import property1 from "../../asset/images/property/big-img-01.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowDown, FaSearch } from "react-icons/fa";
import {
  faBathtub,
  faBed,
  faCalendar,
  faLocation,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
import { Slider } from "@material-ui/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import Detailheader from "../../component/Detailheader";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "../Custom.css";
// import required modules
import { Navigation } from "swiper";
import axios from "../../utlilites/axios";
import Dropdown from "react-bootstrap/Dropdown";
import MultiRangeSlider from "../../component/Multirangeslider/MultiRangeSlider";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";

export const AllProperty = () => {
  const { id } = useParams();
  //States
  const [loader, setLoader] = useState(true);
  const [allproperty, setAllproperty] = useState();
  const [range, setRange] = useState(null);
  const [path, setPath] = useState();
  const navigate = new useNavigate();
  const [val, setVal] = useState(0);
  const [filtershow, setFiltershow] = useState(false);
  const [filterproperty, setFilterproperty] = useState([]);
  const [filterroom, setFilterroom] = useState([]);
  const [contact, setContact] = useState(false);
  const [api, setApi] = useState();
  const [name, setName] = useState();
  const [addres, setAddres] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [price, setPrice] = useState();
  const [proprty, setProprty] = useState();
  const [message, setMessage] = useState();
  const [details, setDetails] = useState();
  const [status, setStatus] = useState();
  const [cid, setCid] = useState();

  const handleRange = (e) => {
    setVal(e.target.value);
  };

  const property = () => {
    const token = localStorage.getItem("accesstoken");
    var config = {
      method: "get",
      url: "http://admin.usys.club/api/veiw/property",
      headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(true);
        setAllproperty(response.data.property);

        setPath(response.data.imagepath);
        console.log(response, "checking");
        // console.log(response.data.imagepath, "path");
        if (response.status) {
          setLoader(false);
        }
        if (token) {
          setContact(true);
        } else {
          setContact(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const filter = () => {
    try {
      
      const filteration = new FormData();
      if (range !== null) {
        if (range.min) {
          filteration.append("startRent", range.min);
        }
        if (range.max) {
          filteration.append("endRent", range.max);
        }
      } 
      if (filterproperty.length !== 0) {
        filterproperty.map((e, i) => {
          filteration.append(`property_type[${i}]`, e);
        });
        console.log(filterproperty, "filter.......");
      }
      if (filterroom.length !== 0) {
        filteration.append("bedroom", filterroom);
      }
  
      var config = {
        method: "POST",
        url: `http://admin.usys.club/api/search/property/advance`,
        data: filteration,
        // headers: {
        //   Accept: "application/json",
        //   Authorization: `Bearer ${tokens}`,
        // },
      };
  
      axios(config)
        .then(function (response) {
          setAllproperty(response.data.property);
          // console.log(response.data, "filter......");
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error )
    }
  };

useEffect(()=> {
  filter();
},[])

  useEffect(() => {
    property();
    setLoader(true);
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setFilterproperty([...filterproperty, value]);
    } else {
      setFilterproperty(filterproperty.filter((e) => e !== value));
    }
    console.log(value, checked, "checking.........");
  };
  const handleChange1 = (e) => {
    const value = e.target.value;
    setFilterroom(value);

    // const checked = e.target.checked;

    // if (checked) {
    //   setFilterroom([...filterroom, value]);
    // } else {
    //   setFilterroom(filterroom.filter((e) => e !== value));
    // }
    console.log(filterroom, "checking.........");
  };

  const handleSubmit = () => {
    console.log(filterproperty, "filter.......");
    console.log(filterroom, "filter.......");
  };

  function contactid(id) {
    const token = localStorage.getItem("accesstoken");
    var config = {
      method: "get",
      url: `http://admin.usys.club/api/detail/property/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(true);
        console.log(response.data.property, "propertydetails");
        setDetails(response.data.property);
        setAddres(response.data.property?.address);
        setPrice(response?.data?.property?.floor?.map(f => f.rent))
        // {response.data.property.floor.map((f)=>{
        // setPrice(f.rent);
        // })}
        setProprty(response.data.property.property_type);
        // setCid(response.data.property.id);
        setLoader(false);
        if (response.status) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const contactapi = () => {
    const contct = new FormData();
    contct.append("property_id", cid);
    contct.append("name", name);
    contct.append("address", addres);
    contct.append("phone_number", number);
    contct.append("email", email);
    contct.append("price", price);
    console.log(price,"..................")
    contct.append("property_type", proprty);
    contct.append("message", message);
    // const token = localStorage.getItem("accesstoken");f
    var config = {
      method: "post",
      url: `http://admin.usys.club/api/contactus/property`,
      data: contct,
      headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(true);
        setApi(response.data);
        setStatus(response.data.status);

        // setPath(response.data.imagepath);
        console.log(response, "checking");
        // console.log(response.data.imagepath, "path");
        if (response.status) {
          swal({
            title: "Good job!",
            text: response.data.message,
            text: "your message send successfuly",
            icon: "success",
            button: "Ok",
          });
          navigate("/AllProperty");
        } else {
        }
        if (response.status) {
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    // console.log("lund",e)
    setCid(e);
    contactid(e);
    setShow(true);
  };

  return (
    <>
      <Detailheader />
      <br />
      {loader ? <div className="loader"></div> : null}
      <section className="bg-white half-map">
        <div className="container mb-3">
          <div className="row">
            <div className="col-lg-1 ">
              <button
                className="btn btn-success text-white btn-block w-100 round"
                onClick={() => setFiltershow(!filtershow)}
              >
                Filter
              </button>
            </div>
          </div>
        </div>
        {filtershow ? (
          <div className="container mb-3 card p-3 bg-light shadow">
            <div className="row mt-3">
              <div className="col-lg-1"></div>
              <div className="col-lg-3 p-3 me-2 bg-success  round ">
                <label className="form-label text-white">Price Range</label>

                <div className="row">
                  <MultiRangeSlider
                    min={0}
                    max={1000}
                    onChange={({ min, max }) => setRange({ min, max })}
                  />
                </div>
                <br />
              </div>
              <div className="col-lg-3 p-3 me-2 bg-success round">
                <label className="form-label text-white">Property Type</label>
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-autoclose-outside"
                  >
                    All property
                  </Dropdown.Toggle>

                  <Dropdown.Menu variant="success" className="bg-theme ps-2 ">
                    <div className="row">
                      <div className="col-lg-6">
                        <Dropdown.Item href="#/action-1" className="cat action">
                          <label>
                            <input
                              type="checkbox"
                              value="Apartment"
                              onChange={handleChange}
                            />
                            <span>All Appartment</span>
                          </label>
                        </Dropdown.Item>
                      </div>
                      <div className="col-lg-6">
                        <Dropdown.Item href="#/action-2" className="cat action">
                          <label>
                            <input
                              type="checkbox"
                              value="Condo"
                              onChange={handleChange}
                            />
                            <span>All Condo</span>
                          </label>
                        </Dropdown.Item>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <Dropdown.Item
                          href="#/action-3"
                          className="cat action"
                          onChange={handleChange}
                        >
                          <label>
                            <input type="checkbox" value="House" />
                            <span>All House</span>
                          </label>
                        </Dropdown.Item>
                      </div>
                      <div className="col-lg-6">
                        <Dropdown.Item
                          href="#/action-4"
                          className="cat action"
                          onChange={handleChange}
                        >
                          <label>
                            <input type="checkbox" value="Bedrooms" />
                            <span>All Bedrooms</span>
                          </label>
                        </Dropdown.Item>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                {/* <select className="form-select">
                  <option disabled>select Property</option>
                  <option>All Appartment</option>
                  <option>All Condo</option>
                  <option>All House</option>
                  <option>All bedroom</option>
                </select> */}
              </div>

              <div className="col-lg-3 p-3 bg-success round">
                <label className="form-label text-white">Bedroom</label>
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-autoclose-outside"
                  >
                    Bedroom
                  </Dropdown.Toggle>

                  <Dropdown.Menu variant="success" className="bg-theme ps-2">
                    <Dropdown.Item className="">
                      {/* <label>
                        <input
                          type="radio"
                          value="1"
                          onChange={handleChange1}
                        />
                        <span>1</span>
                      </label>
                       */}

                      <select
                        name="work_days"
                        id="id_work_days"
                        multiple
                        onChange={(e) => setFilterroom(e.target.value)}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4+">4+</option>
                      </select>
                    </Dropdown.Item>

                    {/* <Dropdown.Item
                      href="#/action-2"
                      className="cat action"
                      onChange={handleChange1}
                    >
                      <label>
                        <input type="radio" value="2" />
                        <span>2</span>
                      </label>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#/action-3"
                      className="cat action"
                      onChange={handleChange1}
                    >
                      <label>
                        <input type="radio" value="3" />
                        <span>3</span>
                      </label>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#/action-4"
                      className="cat action"
                      onChange={handleChange1}
                    >
                      <label>
                        <input type="radio" value="4" />
                        <span>4+</span>
                      </label>
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-lg-1 mt-6 ">
                <button
                  className="btn btn-success pb-2 round bg-success text-white btn-block w-100"
                  onClick={filter}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        ) : null}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="half-map-full">
                <div className="map-canvas">
                  <Maps />
                </div>
              </div>
            </div>

            <div className="col-lg-6  col-md-12 cardscroll">
              {allproperty?.map((e) => {
                return (
                  <>
                    <div onClick={() => navigate("/propertydetail" + e.id)}>
                      <div className="property">
                        <div className="slider mt-1 ">
                          <Swiper
                            navigation={true}
                            modules={[Navigation]}
                            className="mySwiper"
                          >
                            {e.property_image.map((x) => (
                              <SwiperSlide>
                                <img src={`${path}${x.image}`} alt="" />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    </div>

                    <div className="card p-3 br-theme">
                      <div className="row">
                        <div className="col-lg-9">
                          <h5>
                            {e.property_type}
                            <span className="me-1 ms-5">
                              <FontAwesomeIcon icon={faLocationArrow} />
                            </span>
                            {e.address}
                          </h5>
                          <h4>
                            $
                            {e.floor.map((f) => {
                              return <>{f.rent}</>;
                            })}
                          </h4>
                          <h6>
                            <span className="me-1">
                              <FontAwesomeIcon icon={faBed} />
                            </span>
                            {e.floor.map((f) => {
                              return <>{f.bedroom}</>;
                            })}{" "}
                            Bed
                            <span className="me-1 ms-3">
                              <FontAwesomeIcon icon={faBathtub} />
                            </span>
                            {e.floor.map((f) => {
                              return <>{f.bathroom}</>;
                            })}
                            Bath{" "}
                            <span className="me-1 ms-3">
                              <FontAwesomeIcon icon={faLocation} />
                            </span>
                            {e.floor.map((f) => {
                              return <>{f.unit_size}</>;
                            })}
                            Sq.ft
                            <span className="me-1 ms-3">
                              <FontAwesomeIcon icon={faCalendar} />
                            </span>
                            Available :
                            {e.floor.map((f) => {
                              return <>{f.availability}</>;
                            })}
                          </h6>
                        </div>

                        {/* <div className="col-lg-1"></div> */}
                        {contact ? (
                          <div className="col-lg-3">
                            <button
                              className="btn btn-success text-white btn-block w-100 round"
                              onClick={() => handleShow(e.id)}
                            >
                              Contact
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/* Modal Start */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conatct landlord</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="row">
            <Form.Group className="col-lg-6 mb-3 d-none">
              <Form.Label>Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Name"
                value={cid}
                onChange={(e) => setCid(e.target.value)}
                autoFocus
                // onChange={}
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Name"
                onChange={(e) => setName(e.target.value)}
                autoFocus
                // onChange={}
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                value={details ? details?.address : "No address Found"}
                placeholder="Enter Address"
                onChange={(e) => setAddres(e.target.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-3">
              <Form.Label>Phone Number </Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter Your Phone Number"
                onChange={(e) => setNumber(e.target.value)}
                autoFocus
                // onChange={}
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
                // onChange={}
              />
            </Form.Group>
            <Form.Group className="col-lg-6 mb-3">
              <Form.Label>Price</Form.Label>
              {details?.floor?.map((f) => (
                <Form.Control
                  type="text"
                  // placeholder="Price"
                  value={f.rent}
                  onChange={(e) => setPrice(e.target.value)}
                  autoFocus
                />
              ))}
            </Form.Group>

            <Form.Group className="col-lg-6 mb-3">
              <Form.Label>Property</Form.Label>
              <Form.Control
                type="text"
                placeholder="Property Name"
                onChange={(e) => setProprty(e.target.value)}
                value={details ? details?.property_type : "not found"}
                autoFocus
              />
            </Form.Group>
            <Form.Group className=" col-lg-12 mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={contactapi}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal end */}
    </>
  );
};

export default AllProperty;
