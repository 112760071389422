import React, {useState } from "react";
import Authfooter from "../../component/Authfooter";
import Authheader from "../../component/Authheader";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { setSession } from "../../utlilites/jwt";
import swal from "sweetalert";
import axios from "../../utlilites/axios";

export const Landlordlogin = () => {

  const [loader, setLoader] = useState(false);

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });
  const Navigate = useNavigate();
  const landlogin = async (V) => {
    try {
      const response = await axios.post("/landlordlogin", V);
      console.log(response, "working");
      const { message, status, token, user } = response.data;
      if (status == true) {
        setSession(token, user.id);
        swal({
          title: "Good job!",
          text: "Landlord Login SuccessFully!",
          icon: "success",
          button: "Ok",
        });
        Navigate("/landlorddashboard");
        setLoader(false)
      } else {
      }
    } catch (error) {
      setLoader(false)
      console.log(error?.message);
      swal({
        title: "Something Went Wrong",
        text: error?.message,
        icon: "error",
        dangerMode: true,
      });
    }
  };


  return (
    <>
    {loader ? <div className="loader"></div> : null}
      <Authheader />
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          // same shape as initial values
          landlogin(values);
        }}
        >
        {({ errors, touched }) => (
          <Form>
            <div className="container-fluid py-1-7 bg-login">
              <div className="row">
                <div className="offset-lg-4 col-lg-4">
                  <div className="card shadow border-radious bg-transparent">
                    <div className="card-header">
                      <h4 className="color fw-bolder text-center">
                        Welcome To Landlord Login
                      </h4>
                    </div>
                    <div className="card-body">
                      <label className="form-label fw-bolder fs-6 text-white">
                        Email
                      </label>
                      <Field
                        name="email"
                        type="email"
                        className="col-lg-12 form-control mb-3"
                        placeholder="Enter Your Email"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                      <label className="form-label fw-bolder fs-6 text-white">
                        Password
                      </label>
                      <Field
                        name="password"
                        type="password"
                        className="col-lg-12 form-control"
                        placeholder="Enter Your Password"
                      />
                      {errors.password && touched.password ? (
                        <div className="text-danger">{errors.password}</div>
                      ) : null}
                      <button
                        type="submit"
                        className="btn btn-success offset-lg-3 col-lg-6 mt-5"
                      >
                        Login
                      </button>

                      <div className="col-lg-12 text-center">
                        <p className="color">
                          If You Don't Have Account ?{" "}
                          <Link to="/Landlordsignup" className="text-white">
                            Sign Up
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <Authfooter />
    </>
  );
};

export default Landlordlogin;
